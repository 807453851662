import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import {
  Container,
  TextContainer,
  Blue,
  Grey,
} from 'components/Containers';
import { TryItButton } from 'components/Button';
import { Seo } from 'components/Seo';
import {
  WhiteHeader,
  WhiteDetails,
  BlueDetails,
} from 'components/Text';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';
import { IFrame } from 'components/IFrame';

import CheckMarkSvg from 'assets/svgs/CheckMark.svg';

import {
  extraLargeMobileMQ,
  largeDesktopMQ,
  largeMobileMQ,
  largeTabletMQ,
  smallDesktopMQ,
  smallMobileMQ,
  smallTabletMQ,
} from 'styles/breakpoints';

const WhiteDetailDiv = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
`;

const DemoImageContainer = styled.div`
  display: flex;
  height: 700px;
  ${largeTabletMQ(`
    height: 800px;
  `)}
  position: relative;
  justify-content: flex-end;
  img {
    border-radius: unset;
    position: absolute;
  }
  ${smallTabletMQ(`
    flex-direction: column;
    height: unset;
  `)}
  ${({ theme }) => smallTabletMQ(`background-color: ${theme.colors.blue[0]}`)}
  .backgroundImage {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const MainImageContainer = styled(DemoImageContainer)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${smallTabletMQ(`
    height: unset;
  `)}
`;

const DemoSide = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  justify-content: space-evenly;
  z-index: 2;
  > * {
    z-index: 2;
    position: relative;
  }
  box-sizing: border-box;

  ${smallTabletMQ(`
    width: 100%;
    height: unset;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  `)}
`;

const GreyDemoSide = styled(DemoSide)`
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.rgba[0]};
  width: 50%;
  ${smallDesktopMQ(`
    width: 60%;
  `)}
  ${largeTabletMQ(`
    width: 70%;
  `)}
  ${smallTabletMQ(`
    position: unset;
    width: 100%;
  `)}
`;

const Gradient = styled(GreyDemoSide)`
  width: 100%;
  background-color: unset;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.rgba[2]} 00%,
    ${({ theme }) => theme.colors.rgba[1]}
  );
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const RadialGradient = styled(Blue)`
  /* radial gradient light grey very low opacity */
  /* light grey at 10% opacity */
  background-image: radial-gradient(
    circle at 75% 50%,
    rgba(243, 244, 246, 0.12) 20%,
    transparent
  );
  ${smallTabletMQ(`
    background-image: radial-gradient(
      circle at 75% 100%,
      rgba(243, 244, 246, 0.12) 20%,
      transparent
    );
  `)}
`;

const HomeDetails = styled.h3`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
  ${largeDesktopMQ(`
    font-size: 18px;
  `)}
  ${smallTabletMQ(`
    margin: 10px 0;
    font-size: 16px;
  `)}
  color: ${({ theme }) => theme.colors.blue[0]};
`;

const FirstPictureContainer = styled.div`
  ${smallTabletMQ(`
    flex-direction: column;
  `)}
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

const ReversePictureContainer = styled(FirstPictureContainer)`
  padding-top: 20px;
  ${smallTabletMQ(`
    flex-direction: column-reverse;
  `)}
`;

const FlexDiv150 = styled.div`
  flex: 1.5;
`;

const CheckMark = styled(CheckMarkSvg)`
`;

const WhiteDetailsWithCheck = styled(WhiteDetails)`
  width: 100%;
`;

const FlexDiv2 = styled.div`
  flex: 2;
  margin-right: 80px;
  ${smallTabletMQ(`
    margin-right: 0;
  `)}
`;

const StyledTextContainer = styled(TextContainer)`
  min-width: 480px;
  margin-right: 0;
  ${smallTabletMQ(`
    min-width: unset;
    margin-bottom: 15px;
  `)}
  padding-right: 20px;
`;

const StyledTextContainer2 = styled(TextContainer)`
  margin-right: 0;
  margin-left: 80px;
  padding-right: 0;
  ${smallTabletMQ(`
    margin-left: 0;
    margin-top: 15px;
  `)}
`;

const TextContainer2 = styled(StyledTextContainer)`
  min-width: 280px;
  margin-right: 0;
  padding-right: 0;
  flex: 2;
`;

const WidthDetails = styled(WhiteDetails)`
  width: 100%;
`;

const MainContainer = styled.div`
  z-index: 70;
  width: 100%;
  ${({ theme }) => smallTabletMQ(`
    background-image: unset;
    margin-top: 60vw;
    padding-top: 15vw;
    background-color: ${theme.colors.rgba[0]};
  `)}
`;

const AbsFlexDiv = styled(FlexDiv150)`
  ${smallTabletMQ(`
    position: absolute;
    left: 0;
    right: 0;
    top: 30vw;
    width: 100%;
    height: 100%;
    padding: 20px;
    width: 90%;
  `)}
`;

const AbsDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  ${smallTabletMQ(`
    display: none;
    top: -15vw;
  `)}
  ${largeMobileMQ(`
  `)}
`;

const MobileDiv = styled(AbsDiv)`
  display: none;
  ${smallTabletMQ(`
    height: 600px;
    display: block;
  `)}
  ${extraLargeMobileMQ(`
    height: 500px;
  `)}
  ${largeMobileMQ(`
    height: 365px;
  `)}
  ${smallMobileMQ(`
    height: 300px;
  `)}
`;


const Tour = () => (
  <>
    <NavBar />
    <Seo
      title="WireBuddy - Tour"
      description="All your jobs, simply in one place"
      lang="en"
      image="src/assets/svgs/WireBuddyLogoPartial.svg"/>
      <MainImageContainer>
        <AbsDiv>
          <StaticImage
            alt="tour-electric-man"
            src="../images/tour-electric-man-white.jpeg"
            loading="eager"
            className="backgroundImage"
            placeholder="blurred"
          />
        </AbsDiv>
        <MobileDiv>
          <StaticImage
            alt="tour-electric-man"
            src="../images/tour-electric-man-white.jpeg"
            loading="eager"
            className="backgroundImage"
            placeholder="blurred"
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '15% 100%',
            }}
            quality={100}
          />
        </MobileDiv>
        <Gradient />
        <MainContainer>
          <Container>
            <FirstPictureContainer>
              <StyledTextContainer>
                <h1>
                  Bring your contract<br /> business online
                </h1>

                <HomeDetails>
                  Access WireBuddy from any device whether its at home or on site.
                </HomeDetails>

                <TryItButton onClick={async() => navigate('/signup')}>
                  Try it for free
                </TryItButton>
              </StyledTextContainer>
              <AbsFlexDiv>
                <StaticImage
                  alt="WireBuddy app Work Page"
                  src="../images/tour-banner.png"
                  placeholder='tracedSVG'
                />
              </AbsFlexDiv>
            </FirstPictureContainer>
          </Container>
        </MainContainer>
      </MainImageContainer>
    <RadialGradient>
      <Container>
        <ReversePictureContainer>
          <StyledTextContainer>
            <WhiteHeader>
              Create a quote anytime, anywhere
            </WhiteHeader>

            <WhiteDetailDiv>
              <CheckMark/>
              <WidthDetails>
                Send quotes via email or text so your customers get
                back to you quicker than ever.
              </WidthDetails>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <WidthDetails>
                Chat and email support so you can help build relationships
                with your customers and close deals.
              </WidthDetails>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <WidthDetails>
                Automatically convert approved quotes into jobs allowing you to get started sooner.
              </WidthDetails>
            </WhiteDetailDiv>
          </StyledTextContainer>
          <FlexDiv150>
            <StaticImage
              alt="WireBuddy app Quotes Page"
              src="../images/tour-quote-screens.png"
              placeholder='tracedSVG'
            />
          </FlexDiv150>
        </ReversePictureContainer>
      </Container>
    </RadialGradient>
    <Grey>
      <Container>
        <FirstPictureContainer>
          <FlexDiv150>
            <StaticImage
              alt="WireBuddy Calendar Schedule Event Screen"
              src="../images/tour-schedule-screens.png"
              placeholder='tracedSVG'
            />
          </FlexDiv150>
          <StyledTextContainer2>
            <h1>
              Schedule and track <br/>tasks for all your jobs
            </h1>

            <WhiteDetailDiv>
              <CheckMark/>
              <BlueDetails>
                Get reminders for scheduled tasks so every job is completed on time.
              </BlueDetails>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <BlueDetails>
                Automatically notify customers about scheduled
                work so they know when you expect you.
              </BlueDetails>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <BlueDetails>
                Easily block off time to avoid conflicts and
                guarantee your free time stays free.
              </BlueDetails>
            </WhiteDetailDiv>
          </StyledTextContainer2>
        </FirstPictureContainer>
      </Container>
    </Grey>
    <RadialGradient>
      <Container>
        <ReversePictureContainer>
          <StyledTextContainer>
            <WhiteHeader>
              Generate invoices & <br/>receive payments
            </WhiteHeader>

            <WhiteDetailDiv>
              <CheckMark/>
              <WhiteDetailsWithCheck>
                Generate invoices and send them to your customers in just a few clicks.
              </WhiteDetailsWithCheck>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <WhiteDetailsWithCheck>
                Customer can pay invoices anytime through the WireBuddy Customer Portal.
              </WhiteDetailsWithCheck>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <WhiteDetailsWithCheck>
                Accept debit and credit payments in person through WireBuddy with your phone.
              </WhiteDetailsWithCheck>
            </WhiteDetailDiv>

            <WhiteDetailDiv>
              <CheckMark/>
              <WhiteDetailsWithCheck>
                Easily mark invoices as paid manually when paid with cash or cheque.
              </WhiteDetailsWithCheck>
            </WhiteDetailDiv>
          </StyledTextContainer>
          <FlexDiv150>
            <StaticImage
              alt="WireBuddy app Invoice Screen"
              src="../images/tour-invoice-payment.png"
              placeholder='tracedSVG'
            />
          </FlexDiv150>
        </ReversePictureContainer>
      </Container>
    </RadialGradient>
    <Grey>
      <Container>
        <FirstPictureContainer>
          <FlexDiv2>
            <IFrame
              isLazy
              // eslint-disable-next-line max-len
              src="https://player.vimeo.com/video/749392218?h=9f02f8b565&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            />
          </FlexDiv2>
          <TextContainer2>
            <h1>
              Try it for free
            </h1>

            <BlueDetails>
              Create quotes, send invoices, collect payments,
              all so you can manage your day-to-day easier.
            </BlueDetails>

            <TryItButton onClick={async() => navigate('/signup')}>
              Get started
            </TryItButton>

          </TextContainer2>
        </FirstPictureContainer>
      </Container>
    </Grey>
    <Footer />
  </>
);

export default Tour;
